import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col} from "react-bootstrap"
import XmasTimes from "../components/xmastimes"
import Lights from "../components/lights"

class ChristmasOpeningTimes extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Christmas opening times at Capernwray" />
        <Lights />
        <Container className="mt-2 pt-4 mb-5">
          <Row>
            <Col sm={12}>
                <h1 className="mt-5 pt-2">Christmas Opening Times 2022</h1>
              <XmasTimes />
            </Col>
          </Row>
        </Container>
        <div className="pb-5 mb-5">
          <Lights invert />
        </div>
        
      </Layout>
    )
  }
}

export default ChristmasOpeningTimes

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
